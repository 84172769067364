@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
}
.body-cn {
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  min-height: 100vh;
  background-color: #fff;
  color: var(--black1);
}

.homepage-header-background-cn {
  background: url('/cn/homepage-curve.svg') no-repeat center / cover;
}

